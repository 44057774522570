import React from 'react';
import { useTranslation } from 'react-i18next';

const LangSelector = () => {
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const setLang = (value) => {
        localStorage.setItem('langSelect', value);
        i18n.changeLanguage(value);
    }


    return (

        <>
            <li className="dropdown-language">
                <a className="waves-block waves-light translation-button" data-target="translation-dropdown">
                    <span className={`flag-icon flag-icon-${localStorage.getItem('langSelect') === 'es' ? 'es' : 'gb'}`}></span>
                </a>
                <ul className="dropdown-content" id="translation-dropdown" tabIndex="0">
                    <li className="dropdown-item" tabIndex="0">
                        <button className="grey-text text-darken-1" onClick={() => setLang("en")} data-language="en">
                            <i className="flag-icon flag-icon-gb"></i> {t('lang.en')}
                        </button>
                    </li>
                    <li className="dropdown-item" tabIndex="0">
                        <button className="grey-text text-darken-1" onClick={() => setLang("es")} data-language="fr">
                            <i className="flag-icon flag-icon-es"></i> {t('lang.es')}
                        </button>
                    </li>

                </ul>
            </li>
        </>
    )
}

export default LangSelector;


export const SetLang = (value) => {
    const { i18n } = useTranslation();
    //const [setSelectedLang] = useState('en');
    localStorage.setItem('langSelect', value);
    i18n.changeLanguage(value);
}