import React, { Component } from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css';

export default class LoadingFormSteps extends Component {

  render() {
    return (
      <>
        <div className="row">
          <div className="input-field col m3 s12">

            <div>
              <Skeleton height={54} />
            </div>

          </div>

          <div className="input-field col m3 s12">
            <div>
              <Skeleton height={54} />
            </div>

          </div>

          <div className="input-field col m3 s12">
            <div>
              <Skeleton height={54} />
            </div>
          </div>


          <div className="input-field col m3 s12">
            <div>
              <Skeleton height={54} />
            </div>
          </div>

        </div>
        <div className="row">
          <div className="input-field col m3 s12">
            <div>
              <Skeleton height={54} />
            </div>
          </div>
          <div className="input-field col m3 s12">
            <div>
              <Skeleton height={54} />
            </div>
          </div>

          <div className="input-field col m3 s12">
            <div>
              <Skeleton height={54} />
            </div>
          </div>

          <div className="input-field col m3 s12">
            <div>
              <Skeleton height={54} />
            </div>
          </div>
        </div>
        <div className="step-actions">
          <div className="row">
            
            <div className="col m6 s12 mb-3">
              <div>
                <Skeleton height={36} width={188} />
              </div>
            </div>
            <div className="col m6 s12 mb-3">
              <div>
                <Skeleton height={36} width={188} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
