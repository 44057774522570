import 'globals'
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
//import ApolloClient from 'apollo-boost';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  split
  //createHttpLink
} from "@apollo/client";
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from '@apollo/client/link/context';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import './i18n';
import './App.css';
import config from './config';

//const httpLink = createHttpLink({
//  uri: 'https://api.vittapp.com/graphql',
//});

const httpLink = createUploadLink({
  uri: `${config.API_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('authToken');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const wsLink = new GraphQLWsLink(createClient({

  url: `${config.API_URL}/graphql`,

}));

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const client = new ApolloClient({
  link: authLink.concat(splitLink),
  cache: new InMemoryCache()
});

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();
